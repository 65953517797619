import React from "react";
import { Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import CookbookSection from "../../components/cookbookSection";

const SoupsAndStewsCookbookSection = ({ location }) => {
  return (
    <CookbookSection
      location={location}
      sectionLink="soups-and-stews"
      sectionName="Soups and Stews"
      sectionTitle="Soups & Stews"
      prevLink="/cookbook/snacks/"
      nextLink="/cookbook/"
      nextName="Start Over"
    >
      <Row className="d-flex pb-4 pt-4 flex-wrap justify-content-center" id="cookbook-top-text">
        <div className="ps-4 pe-4 pb-4 cookbook-top-section d-flex flex-column">
          <div>A good soup can be super easy and perfect for dinner</div>
          <div className="pt-2">
            <StaticImage width={300} src="../../images/cookbook/stew-in-pan.jpg" alt="Frying pan with bacon" />
          </div>
        </div>
        <div className="ps-4 pe-4 pb-4 cookbook-top-section d-flex flex-column">
          <div className="flex-fill">... unless you want something else</div>
          <div className="pt-2">
            <StaticImage width={300} src="../../images/cookbook/mount-baldy-600x400.jpg" alt="Mount Baldy" />
          </div>
        </div>
      </Row>
    </CookbookSection>
  );
};

export default SoupsAndStewsCookbookSection;
